import React, {useState, useEffect} from 'react';
import {Label, Dropdown, Option, useId} from '@fluentui/react-components';
import usePreferenceClasses from '../../Navigation/Preferences.styles';
import {useTranslation} from 'react-i18next';
import {useUpdateUserPreferences} from '@/api/user';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import ErrorSettingWorkspace from '@/components/Navigation/Breadcrumbs/ErrorSettingWorkspace';
import useGetPreferredEmbeddedWorkspace from '@/api/user/useGetPreferredEmbeddedWorkspace';
import useUpdatePreferredEmbeddedWorkspace from '@/api/user/useUpdatePreferredEmbeddedWorkspace';

function PreferredWorkspaceDropdown() {
    const preferenceClasses = usePreferenceClasses();
    const {t} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const updatePreferredEmbeddedWorkspace = useUpdatePreferredEmbeddedWorkspace();
    const preferredWorkspaceId = useId('preferredWorkspace');
    // preferred workspace data
    const {workspaceName} = useWorkspaceState();
    const {data: preferredEmbeddedWorkspace} = useGetPreferredEmbeddedWorkspace();
    const {data: workspacesData} = useGetWorkspaces();
    const [selectedWorkspace, setSelectedWorkspace] = useState(
        preferredEmbeddedWorkspace ? preferredEmbeddedWorkspace : workspaceName,
    );
    // change workspace
    const handleMenuItemClick = (workspace: Workspace) => {
        updatePreferredEmbeddedWorkspace.mutate(workspace.name, {
            onSuccess: () => {
                setSelectedWorkspace(workspace.name);
                console.log('Preferred workspace updated successfully');
            },
            onError: (error) => {
                console.error('Error updating preferred workspace:', error);
                setErrorPreferredWorkspaceDialogOpen(true);
            },
        });
    };

    const [isErrorPreferredWorkspaceDialogOpen, setErrorPreferredWorkspaceDialogOpen] =
        useState<boolean>(false);

    return (
        <div className={preferenceClasses.field}>
            <h3>{t('SettingsPreferredWorkspaceHeading')}</h3>
            <Label className={preferenceClasses.label}>
                {t('SettingsPreferredWorkspaceDescription')}
            </Label>
            <div className={preferenceClasses.dropdownWrapper}>
                <Dropdown
                    id={preferredWorkspaceId}
                    placeholder={t('SettingsPreferredWorkspaceDropdownPlaceholder')}
                    value={selectedWorkspace}
                    selectedOptions={[selectedWorkspace || '']}
                >
                    {workspacesData?.value?.map((workspace: Workspace, index: number) => (
                        <Option key={index} onClick={() => handleMenuItemClick(workspace)}>
                            {workspace.name}
                        </Option>
                    ))}
                </Dropdown>
                <ErrorSettingWorkspace
                    isOpen={isErrorPreferredWorkspaceDialogOpen}
                    onClose={() => {
                        setErrorPreferredWorkspaceDialogOpen(false);
                        document.getElementById(preferredWorkspaceId)?.focus();
                    }}
                    title={tAdmin('ManageWorkspaces.Errors.SetPreferredWorkspace')}
                ></ErrorSettingWorkspace>
            </div>
        </div>
    );
}

export default PreferredWorkspaceDropdown;
