import React, {useContext, useEffect, useState} from 'react';
import useClasses from '@/components/sections/workspaces/ManageWorkspaces/ManageWorkspaces.styles';
import {
    Menu,
    MenuList,
    MenuTrigger,
    MenuPopover,
    MenuGroup,
    MenuDivider,
    MenuGroupHeader,
    MenuItem,
    MenuButton,
    useRestoreFocusTarget,
    useRestoreFocusSource,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Subtitle2,
    Button,
    Dialog,
} from '@fluentui/react-components';
import UserSettingsModal from '@/components/Navigation/UserSettingsModal';
import {DividerShort16Icon} from '@/components/ui/icons';
import {useNavigate} from 'react-router-dom';
import {
    AddIcon,
    CheckmarkIcon,
    DataUsageIcon,
    WorkspacesIcon,
    SettingsIcon,
    DismissIcon,
} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {useGetUserInfo} from '@/api/app';
import SetPreferredWorkspaceDialog from '@/components/SetPreferredWorkspaceDialog';
import WorkspaceForm from '@/components/sections/workspaces/form/WorkspaceForm';
import {useLocation} from 'react-router-dom';
import ErrorSettingWorkspace from './ErrorSettingWorkspace';
import useGetCurrentWorkspace from '@/api/user/useGetCurrentWorkspace';
import useUpdateCurrentWorkspace from '@/api/user/useUpdateCurrentWorkspace';
import {readFromSessionStorage, writeToSessionStorage} from '@/util/sessionStorage.util';
import {WORKSPACE_NAMESPACE} from '@/components/sections/workspaces/WorkspacesProvider/workspaces.constants';
import {WorkspaceSessionData} from '@/components/sections/workspaces/WorkspacesProvider';
import useGetPreferredEmbeddedWorkspace from '@/api/user/useGetPreferredEmbeddedWorkspace';

/** Dropdown menu for Workspace selection */
function WorkspaceSelector() {
    const {t: tAdmin} = useTranslation('admin');
    const workspaceClasses = useClasses();
    // feature flag
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
    // retrieve workspace and user data
    // only shows first workspace - shows if workspace is set up or not
    const {workspaceName} = useWorkspaceState(); // TODO: is this getting deprecated? this seems to pull from workspacecontext which seems correct

    // for switching workspaces
    const {data: currentWorkspace} = useGetCurrentWorkspace();
    const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceName);
    useEffect(() => {
        // TODO: current workaround until getCurrentWorkspace back-end integration works
        const workspaceFromStorage = readFromSessionStorage(WORKSPACE_NAMESPACE) as Workspace;
        setSelectedWorkspace(workspaceFromStorage.name ? workspaceFromStorage.name : workspaceName);
    }, [workspaceName]);
    // TODO: uncomment once preferred workspace endpoint works from integration testing
    // check if preferred workspace is set
    // Comment out for now to avoid spammed dialogs - TODO: uncomment for testing
    // const {data: preferredWorkspace} = useGetPreferredEmbeddedWorkspace();
    // useEffect(() => {
    //     if (!preferredWorkspace) {
    //         setPreferredWorkspaceDialogOpen(true);
    //     }
    // }, [preferredWorkspace]);

    const updateUserPreferences = useUpdateCurrentWorkspace();

    const {data: userAuthInfo} = useGetUserInfo();
    const {data: workspacesData} = useGetWorkspaces();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    const restoreFocusSourceAttribute = useRestoreFocusSource();
    const handleMenuItemClick = (workspace: Workspace) => {
        if (selectedWorkspace === workspace.name) {
            return;
        }
        if (workspace.path) {
            // go to switching workspace loading page
            navigate(`/workspace/${workspace.name}`);
            const workspaceData = {
                name: workspace.name,
                path: workspace.path,
                managementUrl: workspace.managementUrl,
                podId: workspace.path?.split('/')[1],
            };
            updateUserPreferences.mutate(workspace.name, {
                onSuccess: () => {
                    setSelectedWorkspace(workspace.name);
                    writeToSessionStorage<WorkspaceSessionData>(WORKSPACE_NAMESPACE, workspaceData);
                    console.log('Successfully updated current workspace');
                },
                onError: (error: any) => {
                    console.log('Error updating user preferences current workspace: ', error);
                },
            });
            setTimeout(() => {
                // route back to home page to exit loading screen then refresh
                navigate(`/`);
                window.location.reload();
            }, 3000);
        } else {
            setErrorSwitchingWorkspaceDialogOpen(true);
        }
    };
    // preferred workspace alert
    const [isPreferredWorkspaceDialogOpen, setPreferredWorkspaceDialogOpen] =
        useState<boolean>(false);
    const [isSetErrorSwitchingWorkspaceDialogOpen, setErrorSwitchingWorkspaceDialogOpen] =
        useState<boolean>(false);
    const navigate = useNavigate();

    const location = useLocation();

    return isWorkspacesTestingEnabled && !location.pathname.includes('/tour/') ? (
        <>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <DividerShort16Icon />
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <MenuButton appearance="subtle" size="medium">
                            {selectedWorkspace}
                        </MenuButton>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuGroup>
                                <MenuGroupHeader>
                                    {tAdmin('ManageWorkspaces.MenuButtons.Workspaces')}
                                </MenuGroupHeader>
                                {workspacesData?.value?.map(
                                    (workspace: Workspace, index: number) => (
                                        <MenuItem
                                            key={index}
                                            icon={
                                                workspace.name === selectedWorkspace ? (
                                                    <CheckmarkIcon />
                                                ) : (
                                                    <div className={workspaceClasses.fillerIcon} />
                                                )
                                            }
                                            onClick={() => handleMenuItemClick(workspace)}
                                        >
                                            {workspace.name}
                                        </MenuItem>
                                    ),
                                )}
                            </MenuGroup>

                            {(userAuthInfo?.isAdmin ?? false) && (
                                <>
                                    <MenuDivider />
                                    <MenuGroup>
                                        <MenuItem
                                            icon={<WorkspacesIcon />}
                                            onClick={() => navigate('/manage-workspaces')}
                                        >
                                            {tAdmin(
                                                'ManageWorkspaces.MenuButtons.ManageWorkspaces',
                                            )}
                                        </MenuItem>
                                        <MenuItem
                                            icon={<DataUsageIcon />}
                                            onClick={() => navigate('/usage-monitoring')}
                                        >
                                            {tAdmin('ManageWorkspaces.MenuButtons.CapacityUsage')}
                                        </MenuItem>
                                        <MenuItem icon={<AddIcon />}>
                                            {tAdmin('ManageWorkspaces.MenuButtons.NewWorkspace')}
                                        </MenuItem>
                                    </MenuGroup>
                                </>
                            )}
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<SettingsIcon />}
                                    onClick={() => setSettingsOpen(true)}
                                    {...restoreFocusTargetAttribute}
                                >
                                    {tAdmin('ManageWorkspaces.MenuButtons.SetPreferredWorkspace')}
                                </MenuItem>
                            </MenuGroup>
                        </MenuList>
                    </MenuPopover>
                </Menu>

                <SetPreferredWorkspaceDialog
                    isSetPreferredWorkspaceDialogOpen={isPreferredWorkspaceDialogOpen}
                    onClose={() => {
                        setPreferredWorkspaceDialogOpen(false);
                    }}
                ></SetPreferredWorkspaceDialog>
                <ErrorSettingWorkspace
                    isOpen={isSetErrorSwitchingWorkspaceDialogOpen}
                    onClose={() => {
                        setErrorSwitchingWorkspaceDialogOpen(false);
                    }}
                    title={tAdmin('ManageWorkspaces.Errors.SwitchWorkspaces')}
                ></ErrorSettingWorkspace>
                <UserSettingsModal
                    data-testid="SettingsButton"
                    open={settingsOpen}
                    setOpen={setSettingsOpen}
                    setMenuOpen={setOpen}
                    {...restoreFocusSourceAttribute}
                />
            </div>
        </>
    ) : null;
}

export default WorkspaceSelector;
